.card {
  --typography-body-regular-letter-spacing: 0.15px;

  width: 100%;
  min-height: 320px;
}

.image {
  object-position: center right;
}

@media screen and (--mobile) {
  .card {
    --typography-heading-6-font-size: var(
      --typography-subheading-regular-mobile-font-size
    );
    --typography-heading-6-line-height: var(
      --typography-body-small-line-height
    );

    min-height: 160px;
  }

  .mobileCarouselDisabledCard {
    width: 165px;
    min-height: 110px;
  }
}

.title {
  color: var(--color-grayscale-white);
}
