.card {
  --typography-body-regular-letter-spacing: 0.15px;

  width: 100%;
  min-height: 280px;
}

.superNarrowCard {
  --typography-body-regular-letter-spacing: 0.15px;

  width: 100%;
  min-height: 180px;
}

.desktopCarouselDisabledCard {
  width: 174px;
  min-height: 172px !important;
}

.image {
  object-position: center right;
}

.title {
  z-index: 1;
  position: relative;
}

.actionContainer {
  border: 1px solid var(--category-card-link-border-color);
}

@media screen and (--mobile) {
  .card,
  .superNarrowCard {
    --typography-heading-6-font-size: var(
      --typography-subheading-regular-mobile-font-size
    );
    --typography-heading-6-line-height: var(
      --typography-body-small-line-height:
    );

    min-height: 170px;
    max-width: 253px;
  }

  .title {
    --typography-heading-6-line-height: var(
      --typography-body-regular-line-height
    );
  }
}

@media screen and (--desktop-m) {
  .desktopCarouselDisabledCard {
    width: 243px;
  }
}

@media screen and (--desktop-xl) {
  .desktopCarouselDisabledCard {
    width: 280px;
  }
}
